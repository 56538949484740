/* ------------------------------------------------------------ *\
	nav tabs
\* ------------------------------------------------------------ */

.nav.nav-tabs{
    background: #f1f1f1;

    & .nav-item{
        border: 1px solid #0000001a;
        
        &.nav-link{
            color: black;
            .active{
                background: #007bff;
            }
        }
    }
}

