/* ------------------------------------------------------------ *\
	Inputs
\* ------------------------------------------------------------ */

.alert.alert-danger{
    color: #fff;
    background-color: #ff5667;
    border-color: #ff5667;
    padding: 0.25rem 1.25rem;
    border-radius: 0.45rem;
}

.default_label{
    margin-bottom: 0.1rem;
}

.tab-content{
    &--admin{
        border: 1px solid #dee2e6;
        padding: 20px 20px 5px 20px;
        margin-top: -1px;
    }
} 

.divider{
    overflow: hidden;
    position: relative;
}
.divider > span{
    display: inline-block;
    background: white;
    padding: 0 10px;
    margin: 10px 0;
    font-size: 20px;
    position: relative;
    left: 25px;
}
.divider::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid;
    width: 100%;
    color: #ced4da;
}