@import url("https://fonts.bunny.net/css?family=Nunito");
@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '/node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
@import "/node_modules/daterangepicker/daterangepicker.css";
@import '/public/assets/libraries/select2.min.css';
@import '/public/assets/libraries/select2-bootstrap-5-theme.min.css';
@import '/node_modules/dropzone/dist/basic.css';
@import '/node_modules/dropzone/dist/dropzone.css';
@import '/node_modules/icheck-bootstrap/icheck-bootstrap.css';
@import '/node_modules/toastify-js/src/toastify.css';
@import '/public/assets/libraries/adminlte.css';
/* ------------------------------------------------------------ *\
	Dropzone - modified base css
\* ------------------------------------------------------------ */
.image_previw_container {
  display: flex;
  margin-bottom: 30px;
}
.image_previw_container img {
  margin: auto;
}

.btn__back {
  width: 28px;
  height: 28px;
  font-size: 18px;
  background: #ed6c79;
  text-align: -webkit-center;
  line-height: 30px;
  font-weight: bold;
  color: #fff;
}
.btn__back a {
  color: #fff;
}
.btn__back a:hover {
  color: #fff;
}

.btn__close__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: #ED3B67;
  width: 24px;
  height: 24px;
  line-height: 21px;
  border-radius: 50%;
  padding: 0;
  position: relative;
  color: #fff;
}
.btn__close__icon svg {
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__remove__upload {
  position: absolute;
  top: -10px;
  z-index: 10;
  right: -10px;
}

/* ------------------------------------------------------------ *\
	Dropzone - modified base css
\* ------------------------------------------------------------ */
@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.dropzone_wrapper,
.dropzone_wrapper * {
  box-sizing: border-box;
}

.dropzone_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.dropzone_wrapper.dz-started {
  margin-bottom: 20px;
}

.progress__detail__inner .dropzone_wrapper {
  width: 50%;
}

.dropzone_wrapper.dz-clickable {
  cursor: pointer;
}

.dropzone_wrapper.dz-clickable .dz-message,
.dropzone_wrapper.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone_wrapper.dz-started .dz-message {
  display: none;
}

.dropzone_wrapper.dz-drag-hover {
  border-style: solid;
}

.dropzone_wrapper.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone_wrapper .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone_wrapper .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.dropzone_wrapper .dz-file-preview {
  max-width: 100%;
  margin-bottom: 20px;
  width: calc(49.5% - 0.01px);
  padding: 10px;
  border: 1px solid gray;
  margin-right: 1%;
}

.dropzone_wrapper .dz-preview:hover {
  z-index: 1000;
}

.dropzone_wrapper .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone_wrapper .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone_wrapper .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone_wrapper .dz-preview.dz-image-preview {
  margin-bottom: 20px;
  width: calc(49.5% - 0.01px);
  padding: 10px;
  max-width: 50%;
  max-height: 320px;
  border: 1px solid gray;
  margin-right: 1%;
}

.dropzone_wrapper .dz-preview.dz-image-preview:nth-child(2n) {
  margin-right: 0;
}

.dropzone_wrapper .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.dropzone_wrapper .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 101;
}

.dropzone_wrapper .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone_wrapper .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone_wrapper .dz-preview .dz-details {
  z-index: 20;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone_wrapper .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone_wrapper .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone_wrapper .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone_wrapper .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone_wrapper .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone_wrapper .dz-preview .dz-details .dz-filename span,
.dropzone_wrapper .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone_wrapper .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone_wrapper .dz-preview .dz-image {
  overflow: hidden;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone_wrapper .dz-preview .dz-image img {
  display: block;
}

.dropzone_wrapper .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone_wrapper .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone_wrapper .dz-preview .dz-success-mark,
.dropzone_wrapper .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone_wrapper .dz-preview .dz-success-mark svg,
.dropzone_wrapper .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone_wrapper .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone_wrapper .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone_wrapper .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone_wrapper .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone_wrapper .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone_wrapper .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone_wrapper .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone_wrapper .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone_wrapper .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.dz-no-preview .dz-preview {
  display: none !important;
}

/* ------------------------------------------------------------ *\
	Select 2 JS lib
\* ------------------------------------------------------------ */
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: flex;
}

.select2-container--bootstrap-5 .select2-selection--multiple.select2--admin__search .select2-selection__rendered {
  flex-wrap: nowrap;
}
.select2-container--bootstrap-5 .select2-selection--multiple.select2--admin__search .select2-selection__rendered .select2-selection__choice {
  padding: 0 0.65rem;
}

/* ------------------------------------------------------------ *\
	Inputs
\* ------------------------------------------------------------ */
.alert.alert-danger {
  color: #fff;
  background-color: #ff5667;
  border-color: #ff5667;
  padding: 0.25rem 1.25rem;
  border-radius: 0.45rem;
}

.default_label {
  margin-bottom: 0.1rem;
}

.tab-content--admin {
  border: 1px solid #dee2e6;
  padding: 20px 20px 5px 20px;
  margin-top: -1px;
}

.divider {
  overflow: hidden;
  position: relative;
}

.divider > span {
  display: inline-block;
  background: white;
  padding: 0 10px;
  margin: 10px 0;
  font-size: 20px;
  position: relative;
  left: 25px;
}

.divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid;
  width: 100%;
  color: #ced4da;
}

/* ------------------------------------------------------------ *\
	nav tabs
\* ------------------------------------------------------------ */
.nav.nav-tabs {
  background: #f1f1f1;
}
.nav.nav-tabs .nav-item {
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
}
.nav.nav-tabs .nav-item.nav-link {
  color: black;
}
.nav.nav-tabs .nav-item.nav-link .active {
  background: #007bff;
}