// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// // Bootstrap
@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';


@import '/node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';

@import "/node_modules/daterangepicker/daterangepicker.css";

// @import '/node_modules/sweetalert2/dist/sweetalert2.min.css';

// @import '/node_modules/Select2/select2.css';
@import '/public/assets/libraries/select2.min.css';

@import '/public/assets/libraries/select2-bootstrap-5-theme.min.css';

@import '/node_modules/dropzone/dist/basic.css';

@import '/node_modules/dropzone/dist/dropzone.css';

@import '/node_modules/icheck-bootstrap/icheck-bootstrap.css';

@import '/node_modules/toastify-js/src/toastify.css';

@import '/public/assets/libraries/adminlte.css';

@import '_variables';

@import '_module_image';

@import '_buttons';

@import '_module_dropzone';

@import '_select2';

@import '_inputs';

@import '_nav_tabs';
