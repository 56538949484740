/* ------------------------------------------------------------ *\
	Select 2 JS lib
\* ------------------------------------------------------------ */

.select2-container .select2-selection--multiple .select2-selection__rendered{
    display: flex;
}
.select2-container--bootstrap-5 .select2-selection--multiple.select2--admin__search .select2-selection__rendered{
    flex-wrap: nowrap;
    & .select2-selection__choice{
        padding: 0 .65rem;
    }
}