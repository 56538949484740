.btn__back{
    width: 28px;
    height: 28px;
    font-size: 18px;
    background: #ed6c79;
    text-align: -webkit-center;
    line-height: 30px;
    font-weight: bold;
    color: $white;

    & a{
        color:$white;

        &:hover{
            color: $white;
        }
    }
}


.btn__close__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	background: $purple;
	width: 24px;
	height: 24px;
	line-height: 21px;
	border-radius: 50%;
	padding: 0;
	position: relative;
    color: $white;

	& svg {
		margin: 0 auto;
		text-align: center;
		// color: $white;
		font-size: 13px;
		padding: 0; // ios fix...
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%)
	}
}

.btn__remove__upload {
	position: absolute;
    top: -10px;
    z-index: 10;
    right: -10px;
}

