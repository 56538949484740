/* ------------------------------------------------------------ *\
	Dropzone - modified base css
\* ------------------------------------------------------------ */
.image_previw_container{
	display: flex;
	margin-bottom: 30px;

	& img{
		margin: auto;
	}
}